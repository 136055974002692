import React, { useState, useEffect } from 'react';
import './App.css';

function App() {
  const [value, setValue] = useState('');
  useEffect(() => {
    fetch('/api/hello')
    .then(res => {
      return res.json();
    })
    .then(data => {
      setValue(data.value);
    });
  }, []);
  return (
    <div className="App">
      <header className="App-header">
        <p>Data from: {process.env.REACT_APP_PROXY_HOST}</p>
        <p>{value}</p>
      </header>
    </div>
  );
}

export default App;
